import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css'; 
import logo from "./Images/download.jpeg.png";

const Header = () => {
  const location = useLocation();
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerClasses = `fixed-top ${scrolling ? 'scrolled' : ''}`;

  // Check if the current path is either "/login" or "/signin"
  const isLoginPage = location.pathname === '/login';
  const isSignupPage = location.pathname === '/signin';

  if (isLoginPage || isSignupPage) {
    return null; // Do not render the header on login or signup pages
  }

  return (
    <header className={headerClasses}>
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <nav className="nav-links">
        <a href="/login">Login</a>
        <a href="/signin">Signup</a>
      </nav>
    </header>
  );
};

export default Header;
