// Footer.js
import React from 'react';
import './Footer.css';
import FooterLogo from "./Images/download.jpeg.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-light">
      <div className="footer-container">
        <div className="row">
          <div className="col-md-3 footer-column">
            <img src={FooterLogo} alt="API Illustration" className="footer-images" />
          </div>

          <div className="col-md-3 footer-column">
            <h4>Quick Links</h4>
            <ul className="footer-links">
              <li><a href="#home">Home</a></li>
              <li><a href="#about">About Us</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>

          <div className="col-md-3 footer-column">
            <h4>Contact Us</h4>
            <p>Email: info@codingster.in</p>
            <p>Phone: +91 91215 73950</p>
          </div>

          {/* Add a new column for social icons */}
          <div className="col-md-3 footer-column">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} className="icon" />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} className="icon" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="icon" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="icon" />
              </a>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 text-center footer-copyright">
            <p>&copy; 2024 CODINGSTER . All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
