import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './Admin.css';
import SideNav from './SideNav';



const DeliveryBoy = () => {
  const [apiResponses, setApiResponses] = useState({});
  const [apiParameters, setApiParameters] = useState({});
  const [selectedApiIndex, setSelectedApiIndex] = useState(null);
  const [loading, setLoading] = useState(false);


  const apiUrlList = [
    {
      url: 'https://webapi.adef.tech/Login',
      label: 'Employee / login',
      method: 'POST',
      mode: 'no-cors',
      parameters: [
        { name: 'email', label: 'Email', type: 'text' },
        { name: 'password', label: 'Password', type: 'password' },
        { name: 'usertype', label: 'User Type', type: 'text' },
      ],
    },
    {
      url: 'https://webapi.adef.tech/ForgotPassword',
      label: 'Employee / Forgot password',
      method: 'POST',
      parameters: [
        { name: 'usertype', label: 'User Type', type: 'password' },
        { name: 'email', label: 'Email', type: 'text' },
        {name :'password', label:'Password', type:'password'},
        {name :'name', label:'Name', type:'name'},
      ],
    },
    {
      url: 'https://webapi.adef.tech/Otp_Login',
      label: 'OTP / Login',
      method: 'POST',
      mode: 'no-cors',
      parameters: [
        { name: 'phone number', label: 'Phone Number', type: 'Number' },
        { name: 'usertype', label: 'User Type', type: 'text' },
      ],
    },

    {
      url: 'https://webapi.adef.tech/Get_MyProfile',
      label: 'Employee / Profile',
      method: 'GET',
      mode: 'no-cors',
      parameters: [
        { name: 'usertype', label: 'User Type', type: 'text' },
        { name: 'authorid', label: 'Author ID', type: 'text' },
      ],
    },
    {
      url: 'https://webapi.adef.tech/MyProfile',
      label: 'Employee / Profile',
      method: 'POST',
      parameters: [
        { name: 'usertype', label: 'User Type', type: 'text' },
        { name: 'authorid', label: 'Author ID', type: 'text' },
      ],
    },
  ];

  useEffect(() => {
    // Initialize parameters for each API
    const initialParameters = {};
    apiUrlList.forEach(api => {
      api.parameters.forEach(param => {
        initialParameters[param.name] = '';
      });
    });
    setApiParameters(initialParameters);

    // Initialize responses for each API
    const initialResponses = {};
    apiUrlList.forEach(api => {
      initialResponses[api.url] = null;
    });
    setApiResponses(initialResponses);
  }, []);

  const testApi = async (url, method, index) => {
    try {
      setLoading(true);
      const formData = new URLSearchParams();
  
      if (method === 'POST') {
        apiUrlList[index].parameters.forEach(param => {
          formData.append(param.name, apiParameters[param.name]);
        });
      }
  
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
  
      let options = {
        method,
        headers,
      };
  
      if (method === 'POST') {
        options = {
          ...options,
          body: formData.toString(),
        };
      }
  
      const response = await fetch(url, options);
  
      if (response.ok) {
        const data = await response.json();
        setApiResponses((prevResponses) => ({
          ...prevResponses,
          [url]: data,
        }));
  
        if (apiParameters['usertype'] === '2') {
          
          console.log('Response for usertype 2:', data);
        }
      } else {
        const errorData = await response.json();
        console.error(`HTTP error! Status: ${response.status}`, errorData);
        setApiResponses((prevResponses) => ({
          ...prevResponses,
          [url]: { error: `HTTP error! Status: ${response.status}`, details: errorData },
        }));
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setApiResponses((prevResponses) => ({
        ...prevResponses,
        [url]: { error: 'Error fetching data', details: error.message },
      }));
    }
  };
  
  

  const handleParameterChange = (field, value) => {
    setApiParameters({
      ...apiParameters,
      [field]: value,
    });
  };

  const handleApiSelection = (index) => {
    setSelectedApiIndex(selectedApiIndex === index ? null : index);

    // Initialize parameters for the selected API
    const initialApiParameters = {};
    apiUrlList[index].parameters.forEach(param => {
      initialApiParameters[param.name] = '';
    });
    setApiParameters(initialApiParameters);
  };

  return (
    <div className='row grid-ui'>

      <div className="row">
        <div className="col-md-9 mt-4 p-2">
      
        <div className="static-text">
        <h6>Employee</h6>
      </div>
          <Accordion activeKey={selectedApiIndex !== null ? selectedApiIndex.toString() : null}>
            {apiUrlList.map((api, index) => (
              <Accordion.Item key={index} eventKey={index.toString()} className="my-accordion-item">
                <Accordion.Header onClick={() => handleApiSelection(index)}>
                  <button className={`my-button my-button-${api.method.toLowerCase()}`}>{api.method}</button>{api.label}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="mb-3">
                    <label htmlFor="apiUrl" className="my-form-label">API URL</label>
                    <input
                      type="text"
                      className="form-control my-form-control"
                      id="apiUrl"
                      value={api.url}
                      readOnly
                    />
                  </div>

                  <h2>API Parameters</h2>
                  <form>
                    {api.parameters.map((param, paramIndex) => (
                      <div className="mb-3" key={paramIndex}>
                        <label htmlFor={param.name} className="my-form-label">
                          {param.label}
                        </label>
                        <input
                          type={param.type}
                          className="form-control my-form-control"
                          id={param.name}
                          value={apiParameters[param.name]}
                          onChange={(e) => handleParameterChange(param.name, e.target.value)}
                          placeholder={param.label}
                        />
                      </div>
                    ))}
                  </form>
                  <button className="btn btn-primary" onClick={() => testApi(api.url, api.method, index)}>
                    Test API
                  </button>

                  <div className="my-api-section">
                    <h2 className="my-form-label">API Parameters</h2>
                    <pre className="my-api-response">
                    <SyntaxHighlighter language="json" style={okaidia}>
                      {JSON.stringify(apiParameters, null, 2)}
                      </SyntaxHighlighter>
                      </pre>
                  </div>

                  <div className="my-api-section">
                    <h2 className="my-form-label">API Response</h2>
                    {apiResponses[api.url] && (
                      <SyntaxHighlighter language="json" style={okaidia}>
                        {JSON.stringify(apiResponses[api.url], null, 2)}
                      </SyntaxHighlighter>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default DeliveryBoy;
