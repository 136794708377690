// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./components/HomePage";
import SideNav from './components/SideNav';
import AdminLogin from './components/Admin';
import Product from './components/Product';
import DeliveryBoy from './components/DeliveryBoy';
import Vendor from './components/Vendor';
import Coustomer from'./components/Coustomer';
import Employee from './components/Employee';
import Header from './components/Header';
import Signin from './components/Signin';
import Login from './components/Login';

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <SideNav />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/product" element={<Product />} />
          <Route path="/deliveryboy" element={<DeliveryBoy />} />
          <Route path="/employee" element={<Employee />} />
          <Route path="/coustomer" element={<Coustomer />} />
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/login" element={<Login />} />
          
        </Routes>
      </div>
    </Router>
  );
};

export default App;
