// src/components/Whyapi.js
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './Whyapi.css';
import Imagesprops from "./Images/about-page-left.png";
import 'bootstrap/dist/css/bootstrap.min.css';

const Whyapi = () => {
  return (
  
      <div className="whyapi-section">
        <Row>
          <Col md={6} className="right-content">
            <img src={Imagesprops} alt="API Illustration" className="api-image" />
          </Col>
          <Col md={6} className="left-content">
            <h1 className="big-quote">Unlocking Possibilities with Our API.</h1>
            <p className="small-quote">
              Elevate your projects with our powerful API. <br /> Seamlessly integrate and enjoy the benefits:
            </p>
            <ul>
              <li>Access real-time data effortlessly.</li>
              <li>Enhance user experiences with dynamic content.</li>
              <li>Ensure secure and reliable communication.</li>
            </ul>
          </Col>
        </Row>
      </div>
   
  );
};

export default Whyapi;
