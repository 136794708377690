import React from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';  // Import Link
import { Row, Col, Container } from 'react-bootstrap';
import logoImage from './Images/download.jpeg.png';
import Whyapi from './Whyapi';
import Footer from './Footer';
import { useState } from 'react';

const HomePage = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = () => {
    // Add logic to handle subscription
    console.log(`Subscribed with email: ${email}`);
    // You can send the email to your server or perform other actions
    // Reset the email input field
    setEmail('');
  };

  return (
    <>
      <Container fluid>
        <div className="container-with-background">
          
          <div className="hero-section">
            <Row className='bussiness'>
              
              <Col md={4} className='text-left'>
                <h1 className="big-quote">WELCOME TO THE WORLD OF APIS</h1>
                <div className='Browse-button text-decoration-none'>
                  <p className="small-quote">
                    Your business, connected. <br /> Our APIs seamlessly integrate<br />
                  </p>
                  <Link to="/sidenav" className="cta-button">
                    Browser APIs
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Whyapi />
      <div className="subscription-container">
        <div className="subscription-content">
          <h2>Subscribe for Updates</h2>
          <div className="input-group">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            <button className="subscribe-button text-decoration-none" onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
