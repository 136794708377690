// // SideNav.js
// import React from 'react';
// import { Link, Outlet } from 'react-router-dom';
// import { X, List, PeopleFill, PersonCheck, Cart, Person, BoxArrowLeft } from 'react-bootstrap-icons';
// import './SideNav.css';
// import logo9 from "../components/Images/c_logo_light_transparent.png";

// const SideNav = () => {
//   return (
//     <>
//       <div className="custom-sidebar">
//         {/* Sidebar content */}
//         <nav className="custom-nav vh-auto">
//           <Link to="/" className="custom-nav-logo">
//             <img src={logo9} alt="Logo" className="custom-nav-logo-icon" />
//             <span className="custom-nav-logo-name"></span>
//           </Link>
//           <Link to="/admin" className="custom-nav-link">
//             <PeopleFill className="custom-nav-icon" />
//             <span className="custom-nav-name">Admin</span>
//           </Link>
//           <Link to="/product" className="custom-nav-link">
//             <PersonCheck className="custom-nav-icon" />
//             <span className="custom-nav-name">Product List</span>
//           </Link>
//           {/* Add more links as needed */}
//         </nav>

//         {/* Outlet for rendering nested routes */}
//         <Outlet />
//       </div>
//     </>
//   );
// };

// export default SideNav;
import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { X, PeopleFill, PersonCheck, BoxArrowLeft, Truck, PersonFill, GridFill } from 'react-bootstrap-icons';
import './SideNav.css';
import logo9 from "../components/Images/c_logo_light_transparent.png";

const SideNav = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const excludeRoutes = ['/signin', '/login']; // Add the routes you want to exclude

  // Check if the current route should exclude the SideNav
  const shouldExcludeSideNav = excludeRoutes.some(route => location.pathname.includes(route));

  return (
    <>
      {!isHomePage && !shouldExcludeSideNav && (
        <div className="custom-sidebar">
          {/* Sidebar content */}
          <nav className="custom-nav vh-100">
            <Link to="/" className="custom-nav-logo">
              <img src={logo9} alt="Logo" className="custom-nav-logo-icon" />
              <span className="custom-nav-logo-name"></span>
            </Link>
            <Link to="/admin" className="custom-nav-link">
              <PeopleFill className="custom-nav-icon" />
              <span className="custom-nav-name">Admin</span>
            </Link>
            <Link to="/product" className="custom-nav-link">
              <PersonCheck className="custom-nav-icon" />
              <span className="custom-nav-name">Product List</span>
            </Link>
            <Link to="/deliveryboy" className="custom-nav-link">
              <Truck className="custom-nav-icon" />
              <span className="custom-nav-name">Delivery Boy</span>
            </Link>
            <Link to="/vendor" className="custom-nav-link">
              <GridFill className="custom-nav-icon" />
              <span className="custom-nav-name">Vendor</span>
            </Link>
            <Link to="/coustomer" className="custom-nav-link">
              <PersonFill className="custom-nav-icon" />
              <span className="custom-nav-name">Customer</span>
            </Link>
            <Link to="/employee" className="custom-nav-link">
              <PersonFill className="custom-nav-icon" />
              <span className="custom-nav-name">Employee</span>
            </Link>
          </nav>

          {/* Outlet for rendering nested routes */}
          <Outlet />
        </div>
      )}
    </>
  );
};

export default SideNav;
